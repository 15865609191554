import React from "react";
import BidddyDropdown from "../Dropdown/BidddyDropdown";
import Footer from "../Home/internal/Footer";
import GoodVibes from "../Home/internal/GoodVibes";
import CustomerQuotes from "./internal/CustomerQuotes";
import PricingPlans from "./internal/PricingPlans";
import Quote from "./internal/Quote";

const manharImage =
  "https://imgix.cosmicjs.com/8d355020-9f43-11ed-93ee-cb9a2cd68754-manhar.png";

const miaImage =
  "https://imgix.cosmicjs.com/f90f73e0-9f46-11ed-93ee-cb9a2cd68754-mia.png";

const PricingPage = () => (
  <>
    <div className="px-5">
      <section className="pb-16 pt-32 max-w-6xl mx-auto">
        <h1 className="font-inter font-bold text-bidddyAltGray text-center mx-auto max-w-2xl md:text-5xl text-3xl">
          3 simple plans to upgrade your auctions
        </h1>
        <p className="md:text-xl text-lg text-center my-4">
          Choose a plan, take your fundraising to the next level.
        </p>
      </section>
      <PricingPlans />
      <Quote
        quote="Using their software drives engagement on our socials through the roof"
        username="@TorontoThrive"
        handleUrl="https://www.instagram.com/torontothrive/"
        image={manharImage}
        nameAndTitle="Manhar, Co-Founder"
        imageAlt="Manhar from TorontoThrive"
      />
      <CustomerQuotes />
      <Quote
        quote="Their customer service is 10/10. I can honestly say that there is nothing I dislike"
        username="@Thrift.On"
        handleUrl="https://www.instagram.com/thrift.on/"
        image={miaImage}
        nameAndTitle="Mia, Founder"
        imageAlt="Mia from ThriftOn"
      />
      <section className="max-w-6xl w-full mx-auto my-16">
        <h3 className="font-inter font-bold md:text-5xl text-2xl text-bidddyAltGray mb-8">
          Frequently Asked
          <br /> Questions
        </h3>
        <BidddyDropdown
          question="What is Bidddy and how does it work?"
          answer={`
            <p class="mb-2">
              Bidddy is an all-in-one auction platform built for individuals and
              organizations to create, post and manage social auctions to raise
              funds and grow online at the same time.
            </p>
            <p className="mb-4">Here are just a few things you can do with Bidddy:</p>
            <ul class="list-disc list-inside">
              <li>Create and schedule auctions to Instagram</li>
              <li>
                Reach more people and boost engagement by auto-managing bids made
                in the comment section
              </li>
              <li>
                Reach bidders from multiple places, including web, mobile,
                Instagram and in-person
              </li>
              <li>Manage auctions, bidders, and events</li>
            </ul>
          `}
        />
        <BidddyDropdown
          question="How much does Bidddy cost?"
          answer={`
            <p class="mb-2">
              You can try Bidddy for free, run an event starting at $1000, or hire our social auction experts to help you for an hourly rate.
            </p>
          `}
        />
        <BidddyDropdown
          question="Do you help gather auction items?"
          answer={`
            <p class="mb-2">
              Not at the moment, but let us know if this is something you want! 👉 <a href="mailto:hello@bidddy.com">hello@bidddy.com</a>
            </p>
          `}
        />
        <BidddyDropdown
          question="Is Bidddy safe to use with my Instagram?"
          answer={`
            <p class="mb-2">
              Yes! Bidddy is an approved Instagram tool. This means our entire platform has gone through a rigorous approval process from Instagram themselves.
            </p>
          `}
        />
        <BidddyDropdown
          question="Does Bidddy charge additional fees?"
          answer={`
            <p class="mb-2">
              Nope! Unlike other platforms, we don’t charge payment fees, processing fees, service fees, however you want to name it fees. The plans listed above are what you pay!
            </p>
          `}
        />
      </section>
      <GoodVibes background="bg-transparent" />
    </div>
    <Footer />
  </>
);

export default PricingPage;
