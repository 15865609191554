/* eslint-disable react/no-unescaped-entities */
import React from "react";

const QuoteCard = ({ quote }) => (
  <div className="bg-white rounded-lg p-8">{quote}</div>
);

const CustomerQuotes = () => (
  <section className="max-w-6xl w-full mx-auto flex flex-col gap-4 mb-16">
    <h2 className="font-inter font-bold md:text-4xl text-2xl text-bidddyAltGray mb-8">
      How customers feel about Bidddy
    </h2>
    <QuoteCard
      quote={
        <p className="font-inter md:text-2xl text-lg font-normal text-bidddyAltGray">
          "The experience{" "}
          <span className="text-bidddyPink font-bold">
            working with Bidddy was seamless
          </span>
          "
        </p>
      }
    />
    <QuoteCard
      quote={
        <p className="font-inter md:text-2xl text-lg font-normal text-bidddyAltGray">
          "Bidddy{" "}
          <span className="text-bidddyPink font-bold">
            brought in a different level of engagement&nbsp;
          </span>
          that I didn't think we had before this event"
        </p>
      }
    />
    <QuoteCard
      quote={
        <p className="font-inter md:text-2xl text-lg font-normal text-bidddyAltGray">
          "I can truly say{" "}
          <span className="text-bidddyPink font-bold">
            Bidddy has grown with my account
          </span>
          . It simplifies the bidding process for my customers, and me!"
        </p>
      }
    />
    <QuoteCard
      quote={
        <p className="font-inter md:text-2xl text-lg font-normal text-bidddyAltGray">
          "It's so easy to sign up, and the{" "}
          <span className="text-bidddyPink font-bold">
            customer service is great!
          </span>
          "
        </p>
      }
    />
    <QuoteCard
      quote={
        <p className="font-inter md:text-2xl text-lg font-normal text-bidddyAltGray">
          "Using{" "}
          <span className="text-bidddyPink font-bold">
            Bidddy has made running my business so much more efficient
          </span>
          . It makes the bidding process much easier to handle!"
        </p>
      }
    />
    <QuoteCard
      quote={
        <p className="font-inter md:text-2xl text-lg font-normal text-bidddyAltGray">
          "Bidddy genuinely did make a part of the selling process easier!"
        </p>
      }
    />
    <QuoteCard
      quote={
        <p className="font-inter md:text-2xl text-lg font-normal text-bidddyAltGray">
          "The auto-bidding{" "}
          <span className="text-bidddyPink font-bold">
            saves me a lot of time
          </span>
          . I don't have to monitor my comments 24/7"
        </p>
      }
    />
    <QuoteCard
      quote={
        <p className="font-inter md:text-2xl text-lg font-normal text-bidddyAltGray">
          "I can't wait until we will take on another Bidddy auction!"
        </p>
      }
    />
  </section>
);

export default CustomerQuotes;
