import React from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";

const Quote = ({
  quote,
  nameAndTitle,
  username,
  image,
  imageAlt,
  handleUrl,
}) => (
  <section className="w-full mx-auto flex flex-col max-w-6xl mb-16">
    <div className="text-bidddyAltGray md:text-5xl text-3xl font-inter font-bold flex flex-row">
      <span
        className="text-bidddyPink md:text-8xl text-4xl relative"
        style={{ top: "-20px" }}
      >
        “
      </span>
      <h3 className="font-inter font-bold md:ml-5 ml-2">{quote}</h3>
    </div>
    <div className="flex flex-row items-center ml-12 mt-3">
      {/* image */}
      <Imgix
        src={`${image}?auto=format`}
        height={100}
        width={100}
        htmlAttributes={{
          alt: imageAlt,
          src: `${image}?blur=500&px=4&auto=format`,
        }}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
        className="lazyload blur-up"
      />
      <div className="flex flex-col ml-5">
        <p className="md:text-xl text-base font-inter">{nameAndTitle}</p>
        <a
          className="text-bidddyPink underline font-inter md:text-xl text-base"
          href={handleUrl}
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          {username}
        </a>
      </div>
    </div>
  </section>
);

export default Quote;
